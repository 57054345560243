<template>
  <container ref="app" class="app" @error="init">
    <router-view />
    <toTop />
  </container>
</template>


<script>
import toTop from "@/components/toTop.vue";
export default {
  name: "App",
  components: {
    toTop,
  },
  data() {
    return {};
  },
  mounted() {
    // 获取数据
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.app.status("init", {
          height: "100vh",
        });

        // 获取数据
        const result = await this.$axios("/api/mainstation/homeContactus");

        // 设置数据
        this.$store.commit("footer", result.data);

        // 设置状态
        this.$refs.app.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.app.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/default.scss";
</style>
