<template>
  <div
    class="iconfont"
    :class="['icon-' + name]"
    :style="{
      'font-size': _size,
      'line-height': _size,
      width: _size,
      height: _size,
    }"
  ></div>
</template>

<script>
export default {
  computed: {
    _size() {
      // this.size.replace(/^\drpx/g, `calc(${this.size} / 7.5 * "1vw")`);
      if (/^\d+rpx$/.test(this.size)) {
        return this.size.replace(/^(\d+)rpx/g, `calc($1 / 7.5 * 1vw)`);
      } else {
        return this.size;
      }
    },
  },
  props: {
    name: {
      type: String,
    },
    size: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
@import "//at.alicdn.com/t/font_2739113_bss3ep2ky4c.css";

.iconfont {
  display: block;
  font-size: 16px;
}
</style>
