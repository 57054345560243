import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home.vue'),
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('@/views/brand.vue'),
    meta: { name: '品牌介绍' },
  },
  {
    path: '/courseDetail',
    name: 'courseDetail',
    meta: { name: '课程详情' },
    component: () => import('@/views/courseDetail.vue')
  },
  {
    path: '/caregiverDetail',
    name: 'caregiverDetail',
    meta: { name: '月嫂详情' },
    component: () => import('@/views/caregiverDetail.vue')
  },
  {
    path: '/evaluateDetail',
    name: 'evaluateDetail',
    meta: { name: '评价详情' },
    component: () => import('@/views/evaluateDetail.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    meta: { name: '新闻详情' },
    component: () => import('@/views/newsDetail.vue')
  },
  {
    path: '/caregiver',
    name: 'caregiver',
    redirect: '/caregiver/caregiverService',
    component: () => import('@/views/caregiver.vue'),
    children: [
      {
        path: 'caregiverService',
        name: 'caregiverService',
        meta: { nav: 'caregiver', name: '月嫂' },
        component: () => import('@/views/caregiverService.vue'),
      },
      {
        path: 'caregiverList',
        name: 'caregiverList',
        meta: { nav: 'caregiver', name: '月嫂' },
        component: () => import('@/views/caregiverList.vue'),
      },
      {
        path: 'caregiverUnderstand',
        name: 'caregiverUnderstand',
        meta: { nav: 'caregiver', name: '月嫂' },
        component: () => import('@/views/caregiverUnderstand.vue'),
      },
      {
        path: 'caregiverEvaluate',
        name: 'caregiverEvaluate',
        meta: { nav: 'caregiver', name: '月嫂' },
        component: () => import('@/views/caregiverEvaluate.vue'),
      },
    ]
  },
  {
    path: '/nanny',
    name: 'nanny',
    redirect: '/nanny/nannyService',
    component: () => import('@/views/nanny.vue'),
    children: [
      {
        path: 'nannyService',
        name: 'nannyService',
        meta: { nav: 'nanny', name: '育婴师' },
        component: () => import('@/views/nannyService.vue'),
      },
      {
        path: 'nannyList',
        name: 'nannyList',
        meta: { nav: 'nanny', name: '育婴师' },
        component: () => import('@/views/nannyList.vue'),
      },
      {
        path: 'nannyUnderstand',
        name: 'nannyUnderstand',
        meta: { nav: 'nanny', name: '育婴师' },
        component: () => import('@/views/nannyUnderstand.vue'),
      },
      {
        path: 'nannyEvaluate',
        name: 'nannyEvaluate',
        meta: { nav: 'nanny', name: '育婴师' },
        component: () => import('@/views/nannyEvaluate.vue'),
      },
    ]
  },
  {
    path: '/postpartum',
    name: 'postpartum',
    redirect: '/postpartum/postpartumService',
    component: () => import('@/views/postpartum.vue'),
    children: [
      {
        path: 'postpartumService',
        name: 'postpartumService',
        meta: { nav: 'postpartum', name: '产后康复' },
        component: () => import('@/views/postpartumService.vue'),
      },
      {
        path: 'postpartumList',
        name: 'postpartumList',
        meta: { nav: 'postpartum', name: '产后康复' },
        component: () => import('@/views/postpartumList.vue'),
      },
      {
        path: 'postpartumUnderstand',
        name: 'postpartumUnderstand',
        meta: { nav: 'postpartum', name: '产后康复' },
        component: () => import('@/views/postpartumUnderstand.vue'),
      },
      {
        path: 'postpartumEvaluate',
        name: 'postpartumEvaluate',
        meta: { nav: 'postpartum', name: '产后康复' },
        component: () => import('@/views/postpartumEvaluate.vue'),
      },
    ]
  },
  {
    path: '/university',
    name: 'university',
    redirect: '/university/universityService',
    component: () => import('@/views/university.vue'),
    children: [
      {
        path: 'universityService',
        name: 'universityService',
        meta: { nav: 'university', name: '月嫂大学' },
        component: () => import('@/views/universityService.vue'),
      },
      {
        path: 'universityCourse',
        name: 'universityCourse',
        meta: { nav: 'university', name: '月嫂大学' },
        component: () => import('@/views/universityCourse.vue'),
      },
      {
        path: 'universityEvaluate',
        name: 'universityEvaluate',
        meta: { nav: 'university', name: '月嫂大学' },
        component: () => import('@/views/universityEvaluate.vue'),
      },
      {
        path: 'universityUnderstand',
        name: 'universityUnderstand',
        meta: { nav: 'university', name: '月嫂大学' },
        component: () => import('@/views/universityUnderstand.vue'),
      },
    ]
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    meta: { name: '招商加盟' },
    redirect: '/joinUs/index',
    component: () => import('@/views/joinUs.vue'),
    children: [
      {
        path: 'index',
        name: 'joinUs-index',
        meta: { nav: 'joinUs', name: '招商加盟' },
        component: () => import('@/views/joinUs-index.vue'),
      },
      {
        path: 'news',
        name: 'joinUs-news',
        meta: { nav: 'joinUs', name: '招商加盟' },
        component: () => import('@/views/joinUs-news.vue'),
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    redirect: '/about/news',
    component: () => import('@/views/about.vue'),
    children: [
      {
        path: 'news',
        name: 'news',
        meta: { nav: 'about', name: '关于我们' },
        component: () => import('@/views/news.vue'),
      },
      {
        path: 'store',
        name: 'store',
        meta: { nav: 'about', name: '关于我们' },
        component: () => import('@/views/store.vue'),
      },
      {
        path: 'job',
        name: 'job',
        meta: { nav: 'about', name: '关于我们' },
        component: () => import('../views/job.vue'),
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    component: '/',
  },
]

const router = createRouter({
  history: createWebHashHistory('/assets/ayb/home'),
  routes
})

router.beforeEach((to, from, next) => {

  if (from.path != '/') {
    // 发送路由之前，将上一个页面请求全部取消
    window._axiosPromiseArr.forEach((item, index) => {

      // 取消请求
      item.cancel()

      // 清除队列
      delete window._axiosPromiseArr[index]
    })
  }
  next();
})

export default router
