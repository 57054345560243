import axios from 'axios'

// 配置axios
axios.interceptors.response.use((response) => {
    if (response.data.code == 1) {
        return response.data
    } else {
        return Promise.reject(new Error(response.data.msg || `网络错误`))
    }
}, () => {
    return Promise.reject('网络错误')
})

// 请求队列
window._axiosPromiseArr = []

// request拦截
axios.interceptors.request.use(
    config => {
        config.cancelToken = new axios.CancelToken(cancel => {
            window._axiosPromiseArr.push({ cancel })
        })
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
);

export default {
    install(Vue) {
        Vue.config.globalProperties.$axios = axios
    }
}