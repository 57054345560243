<template>
  <div
    class="KCcontainer"
    :class="{ container_fluid: fluid }"
    :style="containerHeight"
  >
    <slot></slot>
    <template v-if="statusCode == 'init'">
      <!-- 状态初始化 -->
      <div class="KCcontainer-init">
        <!-- 加载中 -->
        <loading />
      </div>
    </template>

    <!-- 状态空数据 -->
    <template v-else-if="statusCode == 'empty'">
      <div class="KCcontainer-empty">
        <!-- <image
          mode="widthFix"
          class="empty-content"
          :src="text.custom || text.list.empty"
        /> -->
        <div class="KCcontainer-empty-content">暂无数据</div>
      </div>
    </template>

    <!-- 状态缺省 -->
    <!-- <template v-else-if="statusCode == 'default'">
      <slot></slot>
    </template> -->

    <!-- 状态错误 -->
    <template v-else-if="statusCode == 'error'">
      <template v-if="$slots.error">
        <slot name="error"></slot>
      </template>
      <!-- 重试 -->
      <template v-else>
        <div class="KCcontainer-retry">
          <!-- 重试-标题 -->
          <div class="KCcontainer-retry-title">
            {{ text.custom || text.list.error }}
          </div>
          <!-- 重试-按钮 -->
          <refresh class="KCcontainer-retry-button" @click="retry"></refresh>
          <!-- <div class="KCcontainer-retry-button" @click="retry">重试</div> -->
        </div>
      </template>
    </template>

    <!-- 状态可加载 -->
    <template v-else-if="statusCode == 'canload'">
      <template v-if="$slots.canload"><slot name="canload"></slot></template>
      <div v-else class="KCcontainer-footer">
        {{ text.custom || text.list.canload }}
      </div>
    </template>

    <!-- 状态加载中 -->
    <template v-else-if="statusCode == 'loading'">
      <template v-if="$slots.loading"><slot name="loading"></slot></template>
      <div v-else class="KCcontainer-footer">
        {{ text.custom || text.list.loading }}
      </div>
    </template>

    <!-- 状态完成 -->
    <template v-else-if="statusCode == 'complete'">
      <template v-if="$slots.complete"><slot name="complete"></slot></template>
      <div v-else class="KCcontainer-footer">
        {{ text.custom || text.list.complete }}
      </div>
    </template>
  </div>
</template>

<script>
import loading from "@/components/loading";
import refresh from "@/components/refresh";
export default {
  components: {
    loading,
    refresh,
  },
  props: {
    default: {
      type: String,
      default: "default",
    },
    /**
     * 流式布局
     */
    fluid: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    default: {
      immediate: true,
      handler(value) {
        this.statusCode = value;
      },
    },
  },
  computed: {
    containerHeight() {
      if (/^\d+/.test(this.height)) {
        return {
          height: this.height,
          overflow: "hidden",
        };
      }
      return {};
    },
  },
  data() {
    return {
      /**
       * 状态码
       * init:初始化，canload:可加载，loading:加载中，complete:所有数据加载完成，default:缺省，error:错误，empty:空页面
       */
      statusCode: "default",
      height: "auto",
      text: {
        custom: "", // 当前自定义文字
        list: {
          init: "",
          canload: "上滑查看更多",
          loading: "加载中",
          complete: "我是有底线的",
          error: "网络错误",
          empty: "./images/empty.png",
        }, // 提示文字列表
      },
    };
  },
  methods: {
    /**
     * 设置状态码或返回状态码
     */
    status(status, options) {
      if (status) {
        // 设置状态
        this.statusCode = status;

        // 设置文本
        this.text.custom = options?.text || "";

        // 设置高度
        this.height = options?.height || "auto";
      } else {
        // 返回状态
        return this.statusCode;
      }
    },

    /**
     * 重试
     */
    retry() {
      // 初始化
      this.status("init", {
        height: this.height,
        text: this.text,
      });

      // 弹出
      this.$emit("error");
    },
  },
};
</script>



<style scoped lang="scss">
// 外框
.KCcontainer {
  // width: stretch;
  // max-width: 1200px;
  // margin: auto;
  position: relative;

  // 流式布局
  &_fluid {
    max-width: initial;
  }

  /* 初始化 */
  &-init {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  /* 重试 */
  &-retry {
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* 重试-标题 */
    &-title {
      width: fit-content;
      padding: 0 10px;
      font-size: 14px;
      margin-bottom: 10px;
      display: flex;
      color: #a7a7a7;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    /* 重试-按钮 */
    &-button {
      // cursor: pointer;
      // width: 80px;
      // height: 28px;
      // font-size: 12px;
      // color: #404040;
      // border: 1px solid #ccc;
      // background-color: #f2f2f2;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // border-radius: 5px;
      // user-select: none;

      cursor: pointer;
      user-select: none;
    }
  }

  // 底部
  &-footer {
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    color: #a7a7a7;
  }
}

/* 空 */
.empty-container {
  width: 100%;
  min-height: 500px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.empty-content {
  width: 500px;
  text-align: center;
}
</style>
