import { createStore } from 'vuex'

export default createStore({
  state: {
    // 底部数据
    footer: {}
  },
  mutations: {
    /**
     * 提交底部数据
     */
    footer(state, data) {
      state.footer = data
    }
  },
  actions: {
  },
  modules: {
  }
})
