<template>
  <div class="toTop" @click="toTop" v-if="show">
    <iconfont class="toTop-icon" name="toTop" />
  </div>
</template>

<script>
export default {
  name: "toTop",
  props: {},
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  mounted() {
    addEventListener("scroll", this.check);
  },
  unmounted() {
    removeEventListener("scroll", this.check);
  },
  methods: {
    /**
     * 检查高度
     */
    check() {
      if (
        document.documentElement.scrollTop >
        document.documentElement.clientHeight / 2
      ) {
        if (this.show == false) this.show = true;
      } else {
        if (this.show == true) this.show = false;
      }
    },
    /**
     * 在线资讯
     */
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .toTop {
    position: fixed;
    z-index: 1001;
    right: 30px;
    bottom: 100px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      font-size: 50px !important;
      color: #ed207b;
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .toTop {
    display: none;
  }
}
</style>
