<template>
  <div class="refresh">
    <iconfont size="24px" name="refresh" />
  </div>
</template>

<script>
export default {};
</script>

<style scop lang="scss">
.refresh {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #F2F2F2;
  color: #a7a7a7;
  box-shadow: -2px 2px 10px rgba($color: #000000, $alpha: 0.1);
}
// .refresh {
//   display: block;
//   text-decoration: none;
//   background-color: #f7f7f7;
//   background-image: -webkit-gradient(
//     linear,
//     left top,
//     left bottom,
//     from(#f7f7f7),
//     to(#e7e7e7)
//   );
//   background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7);
//   background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7);
//   background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7);
//   background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
//   color: #a7a7a7;
//   // margin: 36px;
//   width: 48px;
//   height: 48px;
//   position: relative;
//   border-radius: 50%;
//   box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
//   border: solid 1px transparent;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   &::before {
//     content: "";
//     display: block;
//     background: #fff;
//     border-top: 2px solid #ddd;
//     position: absolute;
//     top: -4px;
//     left: -4px;
//     bottom: -4px;
//     right: -4px;
//     z-index: -1;
//     border-radius: 50%;
//     box-shadow: inset 0px 2px 8px #ddd;
//   }

//   &:active {
//     box-shadow: none;
//     border: solid 1px #a7a7a7;
//   }

//   &:hover {
//     text-decoration: none;
//     color: #555;
//     background: #f5f5f5;
//   }
// }
</style>
