import { createApp } from 'vue'
import axios from '@/api/axios.js'
import App from './App.vue'
import router from './router'
import store from './store'

import container from '@/components/container.vue';
import iconfont from '@/components/iconfont.vue';

createApp(App).use(store).use(router).use(axios).component('container', container).component('iconfont', iconfont).mount('#app')
